import { message, Modal } from 'antd';
import type { BaseData } from '../../utils';
import { request } from '../../utils';
import _ from 'lodash';
import { action, observable, runInAction, toJS } from 'mobx';
import qs from 'qs';
import type { API, Egenie, HomePageType, LayoutStoreInitParams, Menudata, Permission, SrcParams, User } from './interface';
import { EnumVersion } from './interface';
import ResizeHelper from './ResizeHelper';
import { HomeOutlined, UserOutlined, IdcardOutlined } from '@ant-design/icons';
import React from 'react';
import styles from './layoutMenu.less';

async function getPerms(): Promise<void> {
  if (window.top?.RingPermission?.permissionList?.length) {
    return;
  }
  let sysType = 3; //1:聘播播  2: 搭子  3: 播圈儿
  if (window.location.pathname === '/pbb-pc-anchorcircle/lugHome/index') {
    sysType = 2;
  }
  await request<BaseData<string[]>>({
    url: '/resource/resource/permissions/all',
    method: 'GET',
    params: { sysType },
  }).then((res) => {
    if (!window.top?.RingPermission) {
      window.top.RingPermission = {
        checkPermit: () => {
          //
        },
        permissionList: res.data || [],
        getResourceId: () => '',
        hasPermit: () => false,
      };
    } else {
      window.top.RingPermission.permissionList = res.data || [];
    }
  });
}

function combineUrl(oldUrl: string, params: string): string {
  if (typeof oldUrl === 'string') {
    if (typeof params === 'string' && params.length) {
      if (!oldUrl.includes('?')) {
        return `${oldUrl}?${params}`;
      } else {
        return `${oldUrl}&${params}`;
      }
    } else {
      return oldUrl;
    }
  } else {
    return '';
  }
}

function getBusiness(params: string): Record<string, string> {
  const business = {};
  if (params && typeof params === 'string') {
    try {
      params.split('&').forEach((item) => {
        const [key, value] = item.split('=');
        business[key] = value;
      });
    } catch (e) {
      console.error(e);
    }
  }
  return business;
}

export class LayoutStore {
  constructor(props?: LayoutStoreInitParams) {
    this.setProject(props?.project);
    this.setHomePageType(props?.homePageType);
  }

  public srcParams: SrcParams[] = [];

  @observable public project = {
    name: '',
    value: 'egenie-erp-home',
  };

  @observable public resizeDom: ResizeHelper;

  @observable public isMenuItemIcon = true;

  @observable public menuItemIconNumber: number;

  @observable public haveDashboard: false; // 是否展示dashboard, false首页则展示空白页

  @observable public isHoverShowPanel = false; // 是否hover展示子菜单，解决频繁展开子菜单问题

  @observable public userInfo: Partial<User> = {};

  @observable public showSubMenu = false;

  @observable public activeMenuId = null;

  @observable public activeSubMenuId: number;

  @observable public menuData: Array<Partial<Menudata>> = [];

  @observable public activeTabKey: string | number = 0; // 导航栏激活tab,默认首页

  @observable public tabList: Array<Partial<Menudata>> = [
    {
      id: 0,
      name: '首页',
      icon: 'index',
    },
  ]; // 导航栏列表

  @observable public showPassord = false;

  @observable public passwordFormInstance: unknown;

  @observable public homePageTypes: HomePageType[] = [];

  @observable public homePageType = null; // 首页(账户)类型，1:零售商,2:供应商

  @observable public isJoinClothesUnion = false; // 是否已加入不缺货联盟

  @observable public showBannerFlag = false; // 是否展示不缺货联盟banner图

  @observable public associatedShopId = null; // 分销商关联店铺ID

  public originMenu = [];

  public originMenuMap = [];

  public immutableStyle = {
    titleHeight: 16,
    titleMargin: 30,
    itemHeight: 14,
    itemMargin: 24,
    blockMargin: 86,
    blockWidth: 240,
    lineTop: 74,
  };

  public handleWindow = () => {
    window.top.RingPermission = {
      openTab: this.handleOpenTab, // 打开菜单，需要传入完整的菜单信息
      openTabId: this.handleOpenTabId, // 打开菜单，只需要传入菜单ID
      closeTab: this.handleTabRemove,
      activeTabKey: this.activeTabKey,
      beforeCloseDict: {},
      toggleVersion: this.toggleVersion,
    };
    // const EgeniePermission: Permission = {
    //   permissionList: [],
    //   checkPermit: (iframe, iframeId) => {
    //     const list = EgeniePermission.permissionList;
    //     const resourceId = EgeniePermission.getResourceId(iframe, iframeId);
    //     if (!resourceId) {
    //       return;
    //     }
    //     const ele = iframe.document.querySelectorAll('[permission]');
    //     ele.forEach((item) => {
    //       const id = item.getAttribute('permission');
    //       if (list.includes(`${resourceId}${id}`)) {
    //         return null;
    //       }
    //
    //       item.remove();
    //     });
    //   },
    //   getResourceId(iframe, iframeId) {
    //     return iframeId ? `${iframeId}_` : `${iframe.frameElement.id}_`;
    //   },
    //
    //   hasPermit(iframe, permission) {
    //     const list = EgeniePermission.permissionList;
    //     const resourceId = EgeniePermission.getResourceId(iframe);
    //
    //     if (!resourceId) {
    //       return null;
    //     }
    //
    //     return list.includes(`${resourceId}_${permission}`);
    //   },
    // };
    // window.top.EgeniePermission = EgeniePermission;
  };

  @action
  public _init_ = () => {
    const dom: any = document.getElementById('rootSider');
    const menuLayoutDom: any = document.getElementById('menuLayout');
    this.menuItemIconNumber = menuLayoutDom?.scrollHeight;
    const resizeDom = new ResizeHelper(dom);

    // 监听
    resizeDom.onResize(
      action(() => {
        this.handleMenuItemHeight(this.originMenu);
        this.resizeChange();
      }),
    );
    this.resizeChange();
  };

  @action
  public resizeChange = () => {
    const dom: any = document?.getElementById('rootSider');
    if (!dom.offsetHeight) {
      this.isMenuItemIcon = false;
    }
    if (dom?.offsetHeight - 64 < this?.menuItemIconNumber) {
      this.isMenuItemIcon = false;
    } else {
      this.isMenuItemIcon = true;
    }
  };

  @action public switchHomePageType = async (): Promise<void> => {
    const newType = this.homePageType === 1 ? 2 : 1;
    const next = this.homePageTypes.find((item) => item.homePageType === newType);
    this.homePageType = newType;
    await request({ url: `/api/iac/resource/update/homePageType?homePageType=${newType}` });
    next && window.location.replace(next.indexUrl);
  };

  @action public toggleVersion: Egenie['toggleVersion'] = async (resourceId, versionType, params = '') => {
    let menuItem: Partial<Menudata>;
    (function dfs(data: Array<Partial<Menudata>>) {
      (data || []).forEach((item) => {
        if (Number(item.id) === Number(resourceId)) {
          menuItem = item;
        } else {
          // @ts-ignore
          // FIXME 菜单树的数据第一层的children结构被处理成二维数组了，暂时不敢动原来逻辑
          if (item && item.length) {
            // @ts-ignore
            dfs(item);
          }

          if (item.children && item.children.length) {
            dfs(item.children);
          }
        }
      });
    })(this.menuData);

    if (menuItem) {
      if (versionType === EnumVersion.oldVersion) {
        menuItem.url = menuItem.oldUrl;
      } else if (versionType === EnumVersion.newVersion) {
        menuItem.url = menuItem.newUrl;
      }

      try {
        await request({
          url: '/api/iac/resource/version/change',
          method: 'POST',
          data: {
            resourceId,
            versionType,
          },
        });
      } finally {
        this.getActiveSubMenu(
          {
            url: combineUrl(menuItem.url, params),
            id: menuItem.id,
            name: menuItem.name,
            icon: menuItem.icon,
          },
          this.srcParams,
        );
      }
    } else {
      throw new Error(`${resourceId}: 不存在`);
    }
  };

  @action
  public getResourceTab = async () => {
    //todo
    // const req = await request<BaseData<any[]>>({
    //   method: 'GET',
    //   url: '/api/baseinfo/rest/homepage/queryResourceTab',
    // });
    // req?.data?.forEach((item) => {
    //   // iframe页面src携带参数
    //   this.getActiveSubMenu({
    //     ...item,
    //     isFixed: 1,
    //     open: false,
    //   }, this.srcParams, false);
    // });
  };

  public isMenuExists = (id: string | number): boolean => {
    let isShow = false;
    this.originMenuMap?.forEach((item) => {
      if (item.id == id) {
        isShow = true;
      }
    });
    return isShow;
  };

  /**
   * 固定标签或取消固定标签
   * @param id
   * @param isFixed
   */
  @action
  public onFixedClick = async (id: number | string, isFixed: 0 | 1) => {
    if (!id) {
      return;
    }
    this.tabList = this.tabList.map((item) => {
      if (`${item.id}` === `${id}`) {
        item.isFixed = isFixed ? 0 : 1;
      }
      return { ...item };
    });
    let url = '/api/baseinfo/rest/homepage/cancelResourceTab';
    if (isFixed === 0) {
      url = '/api/baseinfo/rest/homepage/regularResourceTab';
    }
    await request({
      method: 'GET',
      url,
      params: { id },
    });
  };

  public handleInit = () => {
    this.getMenuList();
    this.getResourceTab();
    this.getUserInfo();
    getPerms();
    this.handleDefaultOpenPage();
    this.handleWindow();
  };

  public setProject = action((project) => {
    if (project) {
      this.project = project;
    }
  });

  public setHomePageType = action((homePageType) => {
    if (homePageType) {
      this.homePageType = homePageType;
    }
  });

  public getUserInfo = action(async () => {
    let url = '/quan/sys/user/info';
    if (window.location.pathname === '/pbb-pc-anchorcircle/lugHome/index') {
      url = '/tiezi/back/sys/user/getInfo';
    }
    console.log(url, window.location.pathname, '-================<<<<');
    const req = await request<BaseData>({
      method: 'GET',
      url,
    });
    this.userInfo = req.data;
  });

  // 别的页面跳到erp & 带有菜单参数
  public handleDefaultOpenPage = action(() => {
    const { href } = window.location;
    const hrefArr = href.split('?');
    const params = qs.parse(hrefArr[1]);
    if (!params.pageId) {
      return;
    }
    this.handleOpenTabId(Number(params.pageId));
  });

  // 根据菜单id 打开菜单
  public handleOpenTabId = action((id: number, params?: string) => {
    request<BaseData<{ resource?: { resourceUrl?: string; resourceName?: string; icon: string; id: string | number } }>>({ url: `/api/iac/resource/getResource/${id}` })
      .then((res): void => {
        if (!res.data || !res.data.resource) {
          return;
        }
        const { resource } = res.data;
        this.handleOpenTab(`${resource.resourceUrl}?${params}`, resource.id, resource.resourceName, resource.icon);
      })
      .catch(() => ({ resource: null }));
  });

  public handleToggleSubMenu = action((flag: boolean, id) => {
    if (!this.isHoverShowPanel) {
      return;
    }
    this.togglePanel(flag);
    this.showSubMenu = flag;
    if (!flag) {
      setTimeout(() => {
        this.activeMenuId = null;
      }, 500);
      return null;
    }
    this.activeMenuId = id;
  });

  // 导航已经打开,更新参数 & 重新加载iframe
  public isOpen = action((id: number | string) => {
    const openTabData: Partial<Menudata> = _.find(
      this.tabList,
      (tab) => tab.id == id, // 存在字符串数字 不能用全等
    );
    return openTabData;
  });

  // 增加 & 更新菜单Tab
  public getActiveSubMenu = action((item, srcParams?: SrcParams[], activeTab = true) => {
    this.activeSubMenuId = item.id;
    this.showSubMenu = false;
    this.togglePanel(false);

    if (activeTab) {
      this.activeTabKey = item.id;
    }

    // iframe页面src携带参数
    const haveParams = _.find(srcParams, ['id', item.id]);
    const result = {
      open: true,
      ...item,
      url: combineUrl(item.url, haveParams?.params ? `${haveParams?.params}&pageId=${item.id}&timestamp=${Date.now()}` : `pageId=${item.id}&timestamp=${Date.now()}`),
    };

    // 定时器解决页面动画卡顿问题
    // 更新tab和iframe
    if (this.isOpen(item.id)) {
      let open = true;
      this.tabList.forEach((_item) => {
        if (_item.id == item.id && !_item.open) {
          open = false;
        }
      });
      if (!open) {
        this.handleOpenTabChange(item.id, result);
        return;
      }
      setTimeout(() => {
        this.handleRefresh(result);
      }, 500);
      return;
    }

    // 增加tab和iframe
    setTimeout(() => {
      this.activeMenuId = null;

      // 避免双击重复打开
      if (this.isOpen(result.id)) {
        return;
      }
      runInAction(() => {
        this.tabList = [...toJS(this.tabList), result];
      });
    }, 500);
  });

  public handleRefresh = (item: Menudata): void => {
    const pageWindow: Partial<HTMLIFrameElement> = document.getElementById(`${item.id}`);
    const { contentWindow } = pageWindow;

    const list = this.tabList.map((tab) => {
      if (tab.id == item.id) {
        if (item.url !== tab.url) {
          return {
            ...tab,
            url: item.url,
          };
        } else {
          contentWindow.location.href = item.url;

          // contentWindow.location.reload();
        }
      }
      return tab;
    });
    this.tabList = list;
  };

  public getMenuList = action(async () => {
    //todo 接口请求
    let res = [];
    let sysType = 3; //1:聘播播  2: 搭子  3: 播圈儿
    if (window.location.pathname === '/pbb-pc-anchorcircle/lugHome/index') {
      sysType = 2;
    }
    const resData = await request<BaseData<any>>({
      method: 'GET',
      url: '/resource/resource/dashboard/menu',
      params: { sysType },
    });
    res = resData?.data || [];
    const newData = [];
    const getUrlData = (data: any[]) => {
      data?.forEach((item) => {
        if (item?.children?.length && !item.url) {
          getUrlData(item.children);
        }
        if (item.url) {
          newData.push(item);
        }
      });
    };
    getUrlData(toJS(res));
    this.originMenu = res || [];
    this.originMenuMap = newData || [];
    setTimeout(() => {
      // this._init_();
    });
    this.handleMenuItemHeight(res);
  });

  public handleMenuItemHeight = action((data) => {
    const { titleHeight, titleMargin, itemHeight, itemMargin, blockMargin, lineTop } = this.immutableStyle;
    const titleTotalHeight = titleHeight + titleMargin; // 标题高度+边距
    const itemTotalHeight = itemHeight + itemMargin; // 叶子菜单高度+边距
    const marginBottom = blockMargin; // 菜单间的间距
    const contentHeight = window.innerHeight - lineTop; // 父级可使用高度
    this.menuData = data.map((item) => {
      const result: Menudata[][] = [[]];
      let line = 0; // 列数
      let currentHeight = 0;

      item.children.map((child) => {
        const height = titleTotalHeight + child.children.length * itemTotalHeight + marginBottom - itemMargin;
        currentHeight += height;

        if (currentHeight > contentHeight) {
          line += 1;
          currentHeight = height;
        }
        (result[line] ? result[line] : (result[line] = [])).push(child);
      });
      return {
        ...item,
        children: result?.filter((item) => item?.length),
      };
    });
  });

  public handleTabRemove: any = action((key: string) => {
    const tab = this.tabList.find((item) => `${item.id}` === key);
    if (!tab) {
      return;
    }
    const beforeClose = window.top.RingPermission.beforeCloseDict[key] || window.top.RingPermission.beforeCloseDict[`${key}`];
    if (beforeClose) {
      typeof beforeClose === 'function' && beforeClose();
    } else {
      // tab.id 可能是数字(菜单栏)，可能是字符串(自定义的)
      const panes = this.tabList.filter((tab) => tab.id != key);
      if (!panes.length || this.activeTabKey != key) {
        this.tabList = panes;
        return null;
      }
      let lastIndex = 0;
      this.tabList.forEach((tab, i) => {
        if (tab.id == key) {
          lastIndex = i;
        }
      });
      let activeTabKey;
      if (lastIndex === panes.length) {
        activeTabKey = panes[lastIndex - 1].id;
      } else {
        activeTabKey = panes[lastIndex].id;
      }
      this.tabList = panes.map((item) => {
        if (item.id == activeTabKey) {
          item.open = true;
        }
        return item;
      });
      this.activeTabKey = activeTabKey;
    }
  });

  // 特殊场景处理关闭页签：组货新增或编辑页面，key中包含_DEAL_ACTICITY且__isNeedModalTip__是1，则需要弹窗提示
  public openCOnfirmCloseModal = (key: string, callback) => {
    Modal.confirm({
      title: '提示',
      content: '有未保存的内容，确认直接关闭吗？',
      onOk: () => {
        callback(key);
        window.sessionStorage.removeItem('__isNeedModalTip__');
      },
      okText: '确认',
      cancelText: '取消',
    });
  };

  public handleTabChange = action((key: string) => {
    this.tabList = this.tabList.map((item) => {
      if (`${item.id}` === `${key}`) {
        if (!item.open) {
          item.open = true;
        }
      }
      return item;
    });
    setTimeout(
      action(() => {
        this.activeTabKey = key;
      }),
    );
  });

  public handleOpenTabChange = action((key: string, params) => {
    this.tabList = this.tabList.map((item) => {
      if (`${item.id}` === `${key}`) {
        if (!item.open) {
          item.open = true;
          item.url = params?.url;
        }
      }
      return item;
    });
    setTimeout(
      action(() => {
        this.activeTabKey = key;
      }),
    );
  });

  /* 其他方式打开页面
     id: 页面id
     name: 页面标题
     url: 页面地址，提供给iframe
     icon: 图标 */
  public handleOpenTab = action((url: string, id: string | number, name: string, icon?: string) => {
    this.getActiveSubMenu({
      id,
      name,
      url,
      icon,
    });
  });

  // 右上角用户信息等
  public handleUserOpertion = action((data, item) => {
    switch (data.key) {
      case 'password':
        this.togglePassword(true);
        break;
      case 'exit':
        this.handleLogout();
        break;
      case data.key:
        item.callback && item.callback();

      // default;
    }
  });

  public togglePassword = action((flag: boolean) => {
    this.showPassord = flag;
  });

  @action public togglePanel = (flag: boolean): void => {
    this.isHoverShowPanel = flag;
  };

  @action public setSrcParams = (srcParams: SrcParams[]) => {
    if (srcParams?.length) {
      this.srcParams = srcParams;
    }
  };

  public handleLogout = async (): Promise<void> => {
    const res: BaseData<{ loginDomainPage: string }> = await request({
      url: '/auth/oauth/logout',
      method: 'DELETE',
    });
    let url = '/pbb-pc-anchorcircle/login/index'; //1:聘播播  2: 搭子  3: 播圈儿
    if (window.location.pathname === '/pbb-pc-anchorcircle/lugHome/index') {
      url = '/#/login';
    }
    localStorage.removeItem('Authorization');
    window.location.href = url;
  };
}

export type ILayoutStore = LayoutStore;
